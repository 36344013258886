/* //////////////////////////////streams//////////////////////////////// */
.search_field {
  margin: 14px;
  float: left;
  width: 100px;
  margin-right: 0px;
}

.search_butten {
  margin: 14px;
  background-color: #04213c;
  height: 30px;
  margin-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  color: aliceblue;
  border-radius: 1px;
  border-end-end-radius: 5px;
}

.stream_full_body {
  height: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.stream_area {
  width: 60%;
  max-width: 660px;
  height: 100%;
  overflow: auto;
}

.filter_area {
  width: 38%;
  max-width: 400px;
  overflow: auto;
}

@media screen and (max-width: 1250px) {
  .stream_full_body {
    display: block;
  }
  .filter_area {
    display: none;
    width: 100%;
    margin-bottom: 20px;
    max-width: 100%;
  }
  .stream_area {
    width: 100%;
    max-width: 100%;
    overflow: visible;
  }
}

.stream_full {
  overflow: hidden;
  width: 98%;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid rgb(230, 230, 230);
  text-align: left;
  border-radius: 3px;
}

.stream_image {
  width: 100%;
  max-height: 1000px;
  object-fit: contain;
}

@media screen and (max-width: 1200px) {
  .stream_area {
    width: 100%;
  }
}

.stream_title_message {
  overflow: auto;
  scrollbar-width: thin;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 14px;
  padding-top: 4px;
}

.stream_title_message::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  scrollbar-width: thin;
}
.stream_title_message::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}

.stream_option_icon_bar {
  padding-top: 466px;
  text-align: center;
  height: 100%;
}

@media screen and (max-width: 1020px) {
  .stream_image {
    margin-top: 5px;
    float: left;
  }

  .stream_title_message {
    max-height: 60px;
    margin-bottom: 5px;
  }
}

.stream_location_company {
  float: right;
  font-size: 14px;
  color: rgb(192, 189, 189);
  text-align: right;
}

.stream_hide_butten {
  padding-left: 10px;
  color: #555555;
  font-size: 12px;
}

.sort_area {
  background-color: white;
  border-radius: 3px;
  padding: 8px 10px;
  padding-bottom: 10px;
  margin: 0px 2%;
  margin-bottom: 15px;
  width: 98%;
  border: 1px solid rgb(230, 230, 230);
}

.member {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
}

.stream_default_sort_area {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 4px rgb(105, 105, 105);
}

.stream_sort_titles {
  font-weight: 500;
  color: #696969;
  font-size: 13px;
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.stream_sort_member_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2.5px solid rgb(45, 110, 25);
  background-color: gray;
  object-fit: cover;
  margin-right: 10px;
}

.stream_sort_member_name {
  font-size: 14px;
  font-weight: 500;
  color: #414141;
  height: 17px;
}

.stream_sort_member_post {
  font-size: 10px;
  font-weight: 500;
  color: #9e9e9e;
  margin-top: 2px;
}

.stream_sort_member_butten {
  color: #003b72;
  font-weight: 600;
  font-size: 11px;
  cursor: pointer;
}

.stream_filter_date {
  padding: 10px;
}

.stream_date_find_butten {
  background: #40c473;
  color: #e6e6e6;
  border-radius: 4px;
  padding: 4px 15px;
  margin: 0px 5px;
  font-size: 14px;
  font-weight: 600;
}

.stream_date {
  padding: 3px 10px;
  margin: 0px 3px;
  border-radius: 5px;
  border: 1px solid rgb(190, 190, 190);
  font-size: 13px;
}

.hm_st_a {
  margin-top: 15px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hm_st_b {
  color: #999;
  padding-right: 8px;
}

.hm_st_c {
  width: 150px;
  height: 32px;
  float: right;
}

.hm_st_d {
  background-color: #4abbc4;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hm_st_e {
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.hm_st_f {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  padding: 5%;
  padding-right: 20%;
  padding-left: 25%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
}

.hm_st_g {
  display: flex;
  align-items: center;
}

.hm_st_h {
  font-size: 13px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid rgb(204, 204, 204);
  margin: 6px 0px;
}

.hm_st_i {
  display: flex;
  align-items: center;
}

.hm_st_j {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin-right: 15px;
}

.hm_st_k {
  font-size: 14px;
  font-weight: 500;
}

.hm_st_l {
  font-size: 12px;
  color: rgb(150, 150, 150);
  font-weight: 400;
}

.hm_st_m {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hm_st_n {
  padding: 15px 20px;
}

.hm_st_o {
  margin-right: 4px;
  margin-left: 10px;
  color: rgb(170, 3, 3);
  font-size: 13px;
}

.hm_st_p {
  margin-right: 4px;
  margin-left: 10px;
  color: rgb(99, 0, 0);
  font-size: 13px;
}

.hm_st_q {
  margin-right: 4px;
  margin-left: 15px;
  color: rgb(11, 105, 192);
  font-size: 13px;
}

.hm_st_r {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: #919191;
}

.hm_st_s {
  width: calc(100% - 50px);
}

.hm_st_t {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  padding: 5%;
  padding-left: 35%;
  padding-right: 30%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
}

.hm_st_u {
  padding: 12px;
  width: 100%;
  background-color: #04213c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.hm_st_v {
  max-height: 80%;
  overflow: auto;
  background: white;
  padding: 10px;
}

.hm_st_w {
  padding: 10px;
  background-color: white;
  box-shadow: 0px 0px 1px gray;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hm_st_x {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 3px;
  object-fit: cover;
}

.hm_st_y {
  display: flex;
  align-items: center;
}

.hm_st_z {
  color: #43659f;
  font-weight: 500;
  font-size: 13px;
}

.hm_st_aa {
  color: Gray;
  font-size: 11px;
  font-weight: 400;
}

.hm_st_ab {
  color: Gray;
  font-size: 12px;
}

.hm_st_ac {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.hm_st_ad {
  color: rgb(173, 170, 170);
  font-size: 12px;
  cursor: pointer;
}
