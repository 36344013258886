.memberdisicon {
  font-size: 13px;
  width: 24px;
  text-align: center;
  color: gray;
}

.profile_inside {
  padding: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 5px;
  box-shadow: 1px 1px 3px gray;
  border-radius: 10px;
}

.profile_sub_title {
  font-weight: 500;
  margin-top: 4px;
  height: 20px;
  font-size: 14px;
}

.profile_sub_data {
  margin-left: 24px;
  color: gray;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

errormsg {
  color: red;
  padding: 20px;
  height: 10px;
}

loading {
  color: gray;
  padding: 10px;
  height: 10px;
}

.singlebody {
  height: 140px;
  width: 300px;
  box-shadow: 1px 1px 5px #999;
  cursor: pointer;
  background-color: white;
  margin: 6px;
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.singlebody:hover {
  transition: 0.35s ease-in-out;
  height: 150px;
  width: 310px;
  margin: 1px;
  box-shadow: 1px 1px 5px rgb(63, 226, 255);
}

.singlebody:not(:hover) {
  transition: 0.35s ease-in-out;
  height: 140px;
  width: 300px;
  margin: 6px;
}

.hm_cy1_a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  margin-top: 14px;
}

.hm_cy1_b {
  color: #999;
  padding-right: 8px;
  padding-left: 2 px;
}

.hm_cy1_c {
  width: 150px;
  height: 32px;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 5px;
  padding: 0px 10px;
}

.hm_cy1_c:focus {
  outline: none !important;
  border: 1px solid rgb(53, 164, 207);
  box-shadow: 0 0 5px #719ece;
}

.hm_cy1_d {
  background-color: #4abbc4;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hm_cy1_e {
  font-size: 15px;
  color: white;
  float: left;
  padding: 5px;
}

.hm_cy1_f {
  height: 70px;
  padding-top: 5px;
}

.hm_cy1_g {
  display: flex;
  justify-content: flex-end;
}

.hm_cy1_h {
  color: gray;
  font-size: 12px;
  padding-left: 10px;
}

.hm_cy1_i {
  color: darkblue;
  font-size: 12px;
  padding-left: 10px;
}

.hm_cy1_j {
  float: right;
  color: #04213c;
  font-size: 12px;
  padding-left: 10px;
}

.hm_cy1_k {
  padding: 12px;
  width: 100%;
  background-color: #04213c;
  height: 120px;
  display: flex;
  justify-content: space-between;
}

.hm_cy1_l {
  border-radius: 40px;
  height: 80px;
  width: 80px;
  margin: 10px;
}

.hm_cy1_m {
  height: 80px;
  width: 80px;
  background-color: black;
  border-radius: 40px;
  font-size: 35px;
  color: gray;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hm_cy1_n {
  font-weight: 500;
  color: white;
  height: 22px;
  overflow: hidden;
}

.hm_cy1_o {
  color: gray;
  font-size: 12px;
}

.hm_cy1_p {
  display: flex;
}

.hm_cy1_q {
  padding: 0px 15px;
  width: 100%;
  background-color: #04213c;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hm_cy1_r {
  box-shadow: 2px 2px 5px #888;
  border-radius: 3px;
  color: white;
  padding: 5px 0px;
  width: 100px;
}

.hm_cy1_s {
  padding-left: 10px;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 10px;
}

.hm_cy1_t {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  margin-right: 15px;
  margin-left: 5px;
}

.hm_cy1_u {
  font-size: 30px;
  padding: 16px;
  padding-top: 14px;
  color: white;
}

.hm_cy1_v {
  background-color: darkblue;
  height: 3px;
  width: 100px;
  margin-bottom: 5px;
}

.hm_cy1_w {
  height: 18px;
  font-size: 12px;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  color: rgb(112, 112, 112);
}

.hm_cy1_x {
  font-size: 10px;
  width: 15px;
}

.hm_cy1_y {
  padding: 12px;
  width: 100%;
  background-color: #04213c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hm_cy1_z {
  padding-top: 20px;
  color: gray;
}

.hm_cy1_aa {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.hm_cy1_ab {
  background: #fefefe;
  padding: 10px 25px;
  padding-top: 20px;
}

.hm_cy1_ac {
  font-size: 14px;
  color: #888888;
  display: flex;
  align-items: center;
  height: 35px;
}

.hm_cy1_ad {
  display: flex;
  align-items: center;
}

.hm_cy1_ae {
  margin-left: 12px;
}

.hm_cy1_af {
  display: flex;
}

.hm_cy1_ag {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hm_cy1_ah {
  padding: 25px;
  padding-top: 0px;
  background-color: #fefefe;
}

.hm_cy1_ai {
  padding: 5px 0px;
  width: 100px;
  background-color: #203e79;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  max-height: 50px;
}

.hm_cy1_aj {
  box-shadow: 1px 1px 8px gray;
  border-radius: 5px;
  width: 150px;
  margin: 10px;
}

.hm_cy1_ak {
  height: 80px;
  width: 150px;
  border-radius: 5px;
  object-fit: cover;
}

.hm_cy1_al {
  overflow: hidden;
  font-weight: 500;
  font-size: 12px;
  color: #04213c;
  cursor: pointer;
  text-align: start;
  margin-left: 10px;
}

.hm_cy1_am {
  color: red;
  font-size: 10px;
  font-weight: 500;
  text-align: start;
  margin-left: 10px;
  margin-bottom: 15px;
  padding-bottom: 5px;
  cursor: pointer;
}

.hm_cy1_an {
  display: flex;
  align-items: center;
}

.hm_cy1_ao {
  display: flex;
  flex-wrap: wrap;
}
